var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PatientInfoLayout", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ openNotesHandler }) {
          return [
            _c(
              "div",
              { staticClass: "tw-px-4 md:tw-px-12 tw-w-full verified-user" },
              [
                !_vm.patientInfo.questions
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0",
                      },
                      [
                        _c("span", { staticClass: "tw-text-2xl" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    )
                  : [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-flex-col tw-flex-grow tw-h-screen",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "header-nav tw-relative md:tw-sticky tw-top-0 tw-py-5 tw-flex tw-justify-between tw-items-center tw-flex-none",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-flex-initial tw-flex tw-items-center tw-text-warning",
                                },
                                [
                                  _vm.previousRoute &&
                                  _vm.previousRoute.meta.title !==
                                    "Verification" &&
                                  _vm.previousRoute.fullPath !== "/"
                                    ? _c("span", {
                                        staticClass:
                                          "back-arrow tw-mr-3 tw-cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push(
                                              _vm.previousRoute.fullPath
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.previousRoute &&
                                  _vm.previousRoute.meta.title !==
                                    "Verification" &&
                                  _vm.previousRoute.fullPath !== "/"
                                    ? _c(
                                        "span",
                                        { staticClass: "tw-text-warning" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.previousRoute.meta.title
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-pr-6" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "sm",
                                        variant: "link",
                                        pill: "",
                                      },
                                      on: { click: openNotesHandler },
                                    },
                                    [_vm._v(" View Notes ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.unreadMessageCounter
                            ? _c(
                                "div",
                                { staticClass: "tw-py-5" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "tw-px-0 tw-underline text-secondary",
                                      attrs: { variant: "link" },
                                      on: { click: _vm.onMessageRedirect },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.unreadMessageCounter) +
                                          " unread " +
                                          _vm._s(
                                            _vm.pluralize(
                                              _vm.unreadMessageCounter,
                                              "message"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "tw-flex-grow" }, [
                            _c("h4", { staticClass: "tw-mb-6" }, [
                              _vm._v("Health Questions"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "health-question-wrapper" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "health-question" },
                                  _vm._l(
                                    _vm.patientInfo.questions,
                                    function (healthQuestion, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "hq-list" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tw-pb-6" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "intake-label",
                                                  attrs: {
                                                    for: `healthQuestion${index}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(index + 1) +
                                                      ". " +
                                                      _vm._s(
                                                        healthQuestion.question
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              healthQuestion.question !=
                                              "Stated allergies"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "pl-0 intake-input",
                                                      style: `color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            healthQuestion.answer
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "pl-0 intake-input",
                                                      style: `color:${healthQuestion.highlightcolor}`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            healthQuestion.answer.replace(
                                                              /(\r\n|\n|\r)/gm,
                                                              "\r\n"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-p-5 tw-flex-none tw-sticky tw-bottom-0 action-btn",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-text-right tw-flex tw-flex-col tw-space-y-3 lg:tw-space-y-0 lg:tw-flex-row lg:tw-justify-end",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-flex-wrap tw-w-full md:tw-block lg:tw-space-x-3",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "tw-text-xs tw-flex-shrink tw-w-full tw-mb-4 md:tw-mb-0 md:tw-w-auto md:tw-text-base",
                                          attrs: {
                                            pill: "",
                                            size: "lg",
                                            variant: "link",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push(
                                                `/patients/${_vm.patientInfo.id}/treatments`
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" View Prescriptions ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "tw-text-xs tw-flex-1 tw-mr-3 md:tw-mr-0 md:tw-text-base",
                                          attrs: {
                                            pill: "",
                                            size: "lg",
                                            variant: "outline-primary",
                                          },
                                          on: { click: _vm.onMessageRedirect },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-flex tw-items-center",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    !_vm.unreadMessageCounter
                                                      ? "tw-block tw-text-center tw-w-full"
                                                      : "",
                                                },
                                                [_vm._v("message")]
                                              ),
                                              _vm.unreadMessageCounter
                                                ? _c(
                                                    "b-badge",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-white",
                                                      attrs: {
                                                        pill: "",
                                                        variant: "secondary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.unreadMessageCounter
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "tw-text-xs tw-flex-1 tw-mr-3 md:tw-mr-0 md:tw-text-base",
                                          attrs: {
                                            size: "lg",
                                            pill: "",
                                            variant: "primary",
                                          },
                                          on: { click: _vm.onPrescribe },
                                        },
                                        [_vm._v(" prescribe ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
              ],
              2
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "unread-message",
                  "hide-header": "",
                  centered: "",
                  "hide-footer": "",
                  size: "md",
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12", sm: "4" } }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "30",
                            height: "27",
                            viewBox: "0 0 30 27",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                              fill: "#1A6A72",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "py-6", attrs: { cols: "12", sm: "8" } },
                      [
                        _c(
                          "h1",
                          {
                            staticClass: "heading mb-4",
                            staticStyle: { "line-height": "30px" },
                          },
                          [
                            _vm._v(" You have "),
                            _c("span", { staticClass: "text-secondary" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.unreadMessageCounter) +
                                  " unread " +
                                  _vm._s(
                                    _vm.pluralize(
                                      _vm.unreadMessageCounter,
                                      "message"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(", do you want to proceed to prescribe? "),
                          ]
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-4 mb-3" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      pill: "",
                                      block: "",
                                      variant: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push(
                                          `/prescriptions/order/${_vm.patientInfo.id}`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Confirm ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      pill: "",
                                      block: "",
                                      variant: "outline-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push(
                                          `/patients/${_vm.patientInfo.id}/message`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Reply ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("ModalDiscussTreatment", {
              on: {
                discuss: function ($event) {
                  return _vm.$router.push(
                    `/patients/${_vm.patientInfo.id}/message`
                  )
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }