<template>
  <PatientInfoLayout>
    <template slot-scope="{ openNotesHandler }">
      <div class="tw-px-4 md:tw-px-12 tw-w-full verified-user">
        <div
          v-if="!patientInfo.questions"
          class="
            tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0
          "
        >
          <span class="tw-text-2xl">Loading...</span>
        </div>
        <template v-else>
          <div class="tw-flex tw-flex-col tw-flex-grow tw-h-screen">
            <div
              class="
                header-nav
                tw-relative
                md:tw-sticky
                tw-top-0
                tw-py-5
                tw-flex
                tw-justify-between
                tw-items-center
                tw-flex-none
              "
            >
              <div
                class="tw-flex-initial tw-flex tw-items-center tw-text-warning"
              >
                <span
                  v-if="
                    previousRoute &&
                    previousRoute.meta.title !== 'Verification' &&
                    previousRoute.fullPath !== '/'
                  "
                  class="back-arrow tw-mr-3 tw-cursor-pointer"
                  @click="$router.push(previousRoute.fullPath)"
                ></span>
                <span
                  class="tw-text-warning"
                  v-if="
                    previousRoute &&
                    previousRoute.meta.title !== 'Verification' &&
                    previousRoute.fullPath !== '/'
                  "
                >
                  {{ previousRoute.meta.title }}
                </span>
              </div>
              <div class="tw-pr-6">
                <b-button
                  size="sm"
                  variant="link"
                  pill
                  @click="openNotesHandler"
                >
                  View Notes
                </b-button>
              </div>
            </div>

            <div v-if="unreadMessageCounter" class="tw-py-5">
              <b-button
                variant="link"
                class="tw-px-0 tw-underline text-secondary"
                @click="onMessageRedirect"
              >
                {{ unreadMessageCounter }} unread
                {{ pluralize(unreadMessageCounter, "message") }}
              </b-button>
            </div>

            <div class="tw-flex-grow">
              <h4 class="tw-mb-6">Health Questions</h4>

              <div class="health-question-wrapper">
                <div class="health-question">
                  <div
                    class="hq-list"
                    v-for="(healthQuestion, index) in patientInfo.questions"
                    :key="index"
                  >
                    <div class="tw-pb-6">
                      <label
                        class="intake-label"
                        :for="`healthQuestion${index}`"
                        >{{ index + 1 }}. {{ healthQuestion.question }}
                      </label>
                      <p
                        v-if="healthQuestion.question != 'Stated allergies'"
                        class="pl-0 intake-input"
                        :style="`color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`"
                      >
                        {{ healthQuestion.answer }}
                      </p>

                      <p
                        v-else
                        class="pl-0 intake-input"
                        :style="`color:${healthQuestion.highlightcolor}`"
                      >
                        {{
                          healthQuestion.answer.replace(
                            /(\r\n|\n|\r)/gm,
                            "\r\n"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <ul
                class="
                  list-unstyled
                  tw-grid tw-grid-cols-1
                  md:tw-grid-cols-2
                  tw-gap-10
                "
              >
                <li
                  class="tw-row-span-1 tw-overflow-hidden"
                  v-for="(healthQuestion, index) in patientInfo.questions"
                  :key="index"
                >
                  <label class="intake-label" :for="`healthQuestion${index}`"
                    >{{ index + 1 }}. {{ healthQuestion.question }}
                  </label>
                  <p
                    v-if="healthQuestion.question != 'Stated allergies'"
                    class="pl-0 intake-input"
                    :style="`color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`"
                  >
                    {{ healthQuestion.answer }}
                  </p>
                  <b-form-textarea
                    v-else
                    disabled
                    max-rows="15"
                    class="pl-0 intake-input"
                    type="text"
                    style="overflow: hidden"
                    :value="
                      healthQuestion.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')
                    "
                    :style="`color:${healthQuestion.highlightcolor}`"
                  ></b-form-textarea>
                </li>
              </ul> -->
            </div>
            <div class="tw-p-5 tw-flex-none tw-sticky tw-bottom-0 action-btn">
              <div
                class="
                  tw-text-right tw-flex tw-flex-col tw-space-y-3
                  lg:tw-space-y-0 lg:tw-flex-row lg:tw-justify-end
                "
              >
                <div
                  class="
                    tw-flex tw-flex-wrap tw-w-full
                    md:tw-block
                    lg:tw-space-x-3
                  "
                >
                  <b-button
                    class="
                      tw-text-xs tw-flex-shrink tw-w-full tw-mb-4
                      md:tw-mb-0 md:tw-w-auto md:tw-text-base
                    "
                    pill
                    size="lg"
                    variant="link"
                    @click="
                      $router.push(`/patients/${patientInfo.id}/treatments`)
                    "
                  >
                    View Prescriptions
                  </b-button>
                  <b-button
                    class="
                      tw-text-xs tw-flex-1 tw-mr-3
                      md:tw-mr-0 md:tw-text-base
                    "
                    pill
                    size="lg"
                    variant="outline-primary"
                    @click="onMessageRedirect"
                  >
                    <span class="tw-flex tw-items-center">
                      <span
                        :class="
                          !unreadMessageCounter
                            ? 'tw-block tw-text-center tw-w-full'
                            : ''
                        "
                        >message</span
                      >
                      <b-badge
                        pill
                        variant="secondary"
                        v-if="unreadMessageCounter"
                        class="ml-2 text-white"
                      >
                        {{ unreadMessageCounter }}
                      </b-badge>
                    </span>
                  </b-button>
                  <b-button
                    class="
                      tw-text-xs tw-flex-1 tw-mr-3
                      md:tw-mr-0 md:tw-text-base
                    "
                    size="lg"
                    pill
                    variant="primary"
                    @click="onPrescribe"
                  >
                    prescribe
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <b-modal id="unread-message" hide-header centered hide-footer size="md">
        <b-row>
          <b-col cols="12" sm="4">
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
                fill="#1A6A72"
              />
            </svg>
          </b-col>
          <b-col cols="12" sm="8" class="py-6">
            <h1 class="heading mb-4" style="line-height: 30px">
              You have
              <span class="text-secondary">
                {{ unreadMessageCounter }} unread
                {{ pluralize(unreadMessageCounter, "message") }}</span
              >, do you want to proceed to prescribe?
            </h1>
            <b-row class="mt-4 mb-3">
              <b-col cols="12" sm="7">
                <b-button
                  pill
                  block
                  variant="danger"
                  @click="
                    $router.push(`/prescriptions/order/${patientInfo.id}`)
                  "
                >
                  Confirm
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" sm="7">
                <b-button
                  pill
                  block
                  variant="outline-primary"
                  @click="$router.push(`/patients/${patientInfo.id}/message`)"
                >
                  Reply
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
      <ModalDiscussTreatment
        @discuss="$router.push(`/patients/${patientInfo.id}/message`)"
      />
    </template>
  </PatientInfoLayout>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import Reminder from "@/components/dashboard/Reminder.vue";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import PatientInformation from "@/components/patients/PatientInformation.vue";
import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";
import ModalDiscussTreatment from "@/components/prescription/ModalDiscussTreatment.vue";

import { nanoid } from "nanoid";

export default {
  name: "verified",
  components: {
    PatientInfoLayout,
    ModalDiscussTreatment,
  },
  data() {
    return {
      patientId: "",
      // isViewingReminders: false,
      // showNotesModal: false,

      // isEditing: false,
      isProcessing: false,
      // editablePatientInformation: {
      //   firstname: "",
      //   lastname: "",
      //   birthday: "",
      //   phoneno: "",
      //   shippingaddress: {
      //     address1: "",
      //     address2: "",
      //     zipcode: "",
      //     city: "",
      //   },
      // },
      patientInformationKey: "",
      unreadMessageCounter: 0,
      conversationId: "",
    };
  },
  // watch: {
  //   isEditing(val) {
  //     if (!val) {
  //       this.patientInformationKey = nanoid();
  //     }
  //   },
  // },
  methods: {
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
    onPrescribe() {
      if (
        this.patientInfo.letdoctorchoose &&
        this.patientInfo.pendingdoctormessage
      ) {
        this.$bvModal.show("discussPrescription");
        return;
      }

      if (this.unreadMessageCounter > 0) {
        this.$bvModal.show("unread-message");
      } else {
        this.$router.push(`/prescriptions/order/${this.patientInfo.id}`);
      }
    },
    onMessageRedirect() {
      this.$router.push(`/patients/${this.patientInfo.id}/message`);
      this.$store.dispatch("message/markConversationAsRead", {
        conversationid: this.conversationId,
      });
    },
    // toggleModal(id) {
    //   this.$root.$emit("bv::hide::modal", id);
    // },
    // chatPatient(patientId) {
    //   localStorage.setItem("patientId", patientId);
    //   this.$router.push(`/message?patientid=${patientId}`);
    // },
    // async onPatientProfileUpdate() {
    //   try {
    //     this.isProcessing = true;
    //     await this.$store.dispatch("patients/updatePatient", {
    //       patientId: this.patientId,
    //       data: this.editablePatientInformation,
    //     });
    //     this.isProcessing = false;
    //     this.isEditing = false;
    //     this.patientInformationKey = nanoid();
    //   } catch (e) {
    //     this.isProcessing = false;
    //     this.$bvToast.toast(e.message, {
    //       title: "Something went wrong",
    //       variant: "danger",
    //       solid: true,
    //       toaster: "b-toaster-bottom-center",
    //     });
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      previousRoute: "globals/previousRoute",
    }),
  },
  async mounted() {
    window.analytics.page();
    this.patientId = this.$route.params.id;
    this.patientInformationKey = nanoid();
    this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });

    const conversation = await this.$store.dispatch(
      "message/getSingleConversationList",
      {
        patientid: this.patientId,
      }
    );
    if (conversation.length > 0) {
      const { data } = await this.$store.dispatch(
        "message/getUnreadMessageCount",
        {
          conversationid: conversation[0].id,
        }
      );
      this.conversationId = conversation[0].id;
      this.unreadMessageCounter = data;
    }
  },
};
</script>

<style lang="scss" scoped>
.health-question-wrapper {
  column-count: 2;
  column-gap: 2em;
}

.health-question {
  display: grid;
  grid-template-rows: 1fr auto;
}

.hq-list > div {
  break-inside: avoid;
}
.btn-link {
  color: #1a6a72;
  text-decoration: underline;
}

.text-muted {
  color: #4f4f4f !important;
}

.intake-label {
  font-size: 12px;
}

.intake-input {
  border-color: #bdbdbd;
}

.header-nav,
.action-btn {
  background-color: rgba(#fff, 0.85);
}

.darkmode .header-nav,
.darkmode .action-btn {
  background-color: rgba(#121212, 0.85);
}
</style>
